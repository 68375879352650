import React, { useState } from "react";
import styled from "styled-components";
import convo from '../../assets/message.png';
import addteam from '../../assets/addmember.png';
import unassigned from '../../assets/contactBookInactive.png';
import sublogo from '../../assets/logo/Logo.png'
//import assigned from '../../assets/assigned.png';
import question from '../../assets/help.png'
import payment from '../../assets/payment.png'
import Home from '../../assets/home.png'
import setting from '../../assets/setting.png'
import payments from '../../assets/paymentsIconss.png'
import cross from '../../assets/cross.png'
import Modal from "../Modal";
import AddTeamMember from "../Settings/AddTeamMember";
import { useSelector } from "react-redux";
import { formatMobileNumber } from "../../utils/Commonfunction";


function LeftMenu(props) {
  const [active, setActive] = useState('Inbox')
  const [isModalopen, setModalOpen] = useState(false);
  const userdata = useSelector(state => state?.auth?.userData)
  const elements = [
    {
      'name': 'Inbox',
      'icon': convo
    },
    {
      'name': 'Bookings',
      'icon': unassigned
    },
    {
      'name': 'Invoices',
      'icon': payments
    },
    {
      'name': 'Quotes',
      'icon': payment
    },
  ]
  const getName = (channel) => {
    if (!channel) {
      return
    }
    const val = channel?.split(' ')

    if (val.length == 2) {
      return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
    }
    else {
      return `${val[0]?.charAt(0).toUpperCase()}`
    }
  }
  return (
    <Main>
      <div style={{ display: 'flex',alignItems:'center',marginBottom:'30px' }}>
        <OutProfile>
          {getName(userdata?.businessName)}
        </OutProfile>
        <IconWrapper>
        <Heading>{userdata?.businessName?.slice(0, 17)}{userdata?.businessName?.length > 17 ? '...' : ''}</Heading>
     <Header>{formatMobileNumber (localStorage.getItem('number'))}</Header>
        
        </IconWrapper>
       
      </div>
      {/* <div style={{display:'flex',marginBottom:'20px',}}>
  <Sublogo1 src={user} alt="Icon 1" />
  <div>
<Heading1>{"userDet"}</Heading1>
<Heading1>{'13656075582'}</Heading1>
</div>
</div> */}
      {elements?.map((x, index) => {
        return (
          <Element className={active === x.name ? "blueclass" : ''} key={index} onClick={() => {
            setActive(x.name)
            props?.setIndex(x.name)
          }}>
            <Icon src={x.icon} alt={x.name} /><span>{x.name}</span>
          </Element>
        )
      })}
      <BottomTexts>
        <Element2 onClick={() => setModalOpen(true)} className={active === 'addteam' ? "blueclass" : ''}><Icon src={addteam} alt='addteam' /><span>Add team members</span></Element2>
        <Element2 className={active === 'settings' ? "blueclass" : ''} onClick={() => {
          setActive('settings')
          props?.setIndex("settings")
        }}><Icon src={setting} alt='settings' /><span>Settings</span></Element2>
        <Element2 className={active === 'Help' ? "blueclass" : ''} ><Icon src={question} alt='settings' /><span>Help</span></Element2>

      </BottomTexts>
      {isModalopen && <Modal >
        <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
          <h2>Add team member</h2>
          <CloseButton onClick={() => setModalOpen(false)}>
          <Icons src={cross}/>
          </CloseButton>
        </div>
        <AddTeamMember
          onCancel={() => setModalOpen(false)}
        />
      </Modal>}
    </Main>
  )
}
export default LeftMenu

const Main = styled.div`
width:210px;
flex-shrink: 0;
overflow: auto; 
background: #FCFBFC;
border-right:1px solid #DDDDDD;
color:black;
margin-top:-10px;
display:flex;
flex-direction:column;
padding-left:20px;
padding-right:15px;
padding-top:25px;
padding-bottom:10px;
height: 100vh;
.blueclass{
background-color:#d4e6ff;
font-weight:600;
&:hover {
    background-color:#d4e6ff;
  }
}
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Heading = styled.p`
font-weight:600;
font-size: 14px;

margin-bottom:-10px;
text-align:start;
`

const Element = styled.div`
font-size:14px;
cursor:pointer;
display:flex;
justify-content:start;
align-items:center;
border-radius:8px;
padding-top:9px;
padding-bottom:8px;
margin-left:-10px;
margin-bottom:5px;
&:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`
const Element2 = styled.div`
font-size:14px;
display:flex;
border-radius:8px;
justify-content:start;
align-items:center;
cursor:pointer;
padding-top:9px;
padding-bottom:8px;
margin-left:-10px;
margin-bottom:3px;

&:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`
const BottomTexts = styled.div`
display:flex;
flex-direction:column;
width:205px;
position:absolute;
bottom:0;
justify-content:flex-end;
margin-bottom:20px;

`
const Icon = styled.img`
height:15px;
width:15px;
margin-right:10px;
padding-left:8px;
color:black
`
const IconWrapper = styled.div`



  display:flex;
  flex-direction:column;
justify-content:start;
`;

const Sublogo = styled.img`
padding:3px;
margin-top:12px;
position: absolute;
top: 0;
left: 0;
width: 130%;
height: 130%;
transition: transform 0.3s ease, opacity 0.3s ease;
${IconWrapper}:hover & {
  transform: rotate(180deg);
  opacity: 0;
}
`
const HoverIcon = styled.img`
position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin-top:18px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  ${IconWrapper}:hover & {
    transform: rotate(0);
    opacity: 1;
  }
`
const Sublogo1 = styled.img`


width: 37px;

margin-top:17px;
height: 37px;


`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:45px;
width:45px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:25px;
margin-left:-5px;
`
const Header=styled.p`
font-size:14px;

`