
import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";


export const getUserDetails = () => async (dispatch, getstate) => {
    const res = await NetworkOps.get(ServiceEnum.userDetails)
   dispatch({ type: "USERDATA", payload: res?.data })
    return res
  }

  export const sendNotification = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(ServiceEnum.notificationSend,data)
   // dispatch({ type: Constants.TYPE.notificationData, payload: res?.data })
    return res
  }
  export const getContactDetail = (data) => async (dispatch, getstate) => {
    dispatch({
      type: 'CONTACTDETAIL',
      payload: {}
    })
    const res = await NetworkOps.post(`${ServiceEnum.getContactDetail}`,{
      "phoneNumber":data
    })
    if(res.status===true){
      if(res?.data?.[0]?._id){
    dispatch({
      type: 'CONTACTDETAIL',
      payload: res?.data?.[0]
    })
  }
  
  }
    const res1 = await dispatch(getUserDetails());
    return res
  }
  export const fetchAllContacts = () => async (dispatch, getstate) => {
    const res = await NetworkOps.get(`${ServiceEnum.fetchAllContactsV2}`)
    let sortdata = res.data
    sortdata.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    dispatch({
      type: 'ALLCONTACTS',
      payload: res?.data
    })
    // dispatch({ type: Constants.TYPE.contactDetails, payload: sortdata })
    return res
  }

  export const submitEditContacts = (data) => async (dispatch, getstate) => {
    const res = await NetworkOps.post(`${ServiceEnum.editContact}?contactId=${data?._id}`, data)
    return res
  }

  export const CreateBookings = (value) => async (dispatch, getstate) => {
 
    const res = await NetworkOps.post(`${ServiceEnum.createBooking}`, value)
    return res
  }
  export const sendInvoice = (value) => async (dispatch, getstate) => {
  
    const res = await NetworkOps.post(`${ServiceEnum.sendInvoice}`, value)
    return res
  }

export const sendQuote = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.sendQoute}`, value)
  return res
}

export const updateDetail = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.updateDetail, data)
  await dispatch(getuserInfo())
  return res
}

export const getuserInfo = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(ServiceEnum.getInfoDetail)
  dispatch({ type: "USERDETAILS", payload: res?.data })
  return res
}
export const getBookings = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.get(`${ServiceEnum.getBookingsApi}`, value)
  return res
}
export const getInvoiceQuote = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.get(`${ServiceEnum.QuoteInvoice}`)
  dispatch({ type: "QUOTEINVOICE", payload: {"invoice":res?.data?.invoice,"quote":res?.data?.quotes}})
  return res
}
export const resetPassword = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.resetpassword, data)
  return res
}
export const saveTaxes = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.saveTax}`,data)
  const res1 = await dispatch(getUserDetails());
  return res
}

export const addNumber = (value) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.AddNumber}`, value)
  dispatch(fetchAllContacts())
  return res
}

export const sendInviteToworker = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.inviteWorker, data)

  return res;
}
export const editQuote = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.editQuote}`, value)
  dispatch(getInvoiceQuote())
  return res
}
export const editInvoice = (value) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.editInvoice}`, value)
  dispatch(getInvoiceQuote())
  return res
}

export const submitDlcBusinessRegistration = (data) => async (dispatch, getstate) => {

  const res = await NetworkOps.post(`${ServiceEnum.dlcbusinessVerfication}`, data)
  return res
}

export const fetchTeamData = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.teamFetch}`, data)
  return res
}
export const assignedJob = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.assignedJob, data)
  return res;
}
export const submitBookingStatus = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.status, data)
  return res
}