import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceData, getQuotedata } from '../store/Actions/Auth.action';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { Box, Modal, TextField } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas'
import { sendNotification } from '../store/Actions/dashboard.action';
import moment from 'moment'
// import { Button } from '@mui/material';


function Quote() {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'white',
        boxShadow: 24,
        px: 2,

    };

    let location = useLocation()

    const searchParams = new URLSearchParams(location.search);
    const referenceNo = searchParams.get('referenceNo');
    const email = searchParams.get('email');
    const [sign, setSign] = useState('')
    const [isModal, setIsModal] = useState(false)
    const [pdf, setPdf] = useState('')
    const sigCanvas = useRef()
    let {id}=useParams()
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const init = async () => {
        const res = await dispatch(getQuotedata({ referenceNo: id}))
        setPdf(res?.data?.pdf)
        await dispatch(sendNotification({
            referenceNo:id,
            date:moment( new Date()).format('DD MMM, hh:mm A'),
            quote:true
        }))
    }
    useEffect(() => {
        init()
    }, [])
    const quotedata = useSelector(state => state?.auth?.quote)
    const quoteuserdata = useSelector(state => state?.auth?.userNameData)
    const [type, setType] = useState(false)
   
    const numberClean = (cleanText) => {
        const formatted = cleanText.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      
        return parseFloat(formatted);
    }
    const putCommaInvalue = (price,quantity) => {

        const cleanText1 = price?.toString().replace(/,/g, '');
        const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return formatted;
    }
     const taxprice=(price,quantity)=>{
        const cleanText = price.replace(/,/g, '');
        const taxes=parseFloat(cleanText?cleanText: 0) * parseFloat(quantity?quantity:1)
        
        return taxes;
      }
    return ( 

        <Main>
            <MainContainer>
                <p style={{ fontSize: '12px', paddingTop: '20px' }}>Quote from</p>
                <h3>{quoteuserdata?.businessName}</h3>
                <Container>
                    <h1>${putCommaInvalue(quotedata?.totalAmount)}</h1>
                    <CustomButton onClick={() => {
                        if (pdf) {
                            window.open(pdf)
                        }
                        else {
                            window.open(`https://app.getnotifi.com/previewquote/${id}`);
                        }


                    }}>
                        View quote
                    </CustomButton>
                    {!pdf ? <CustomButton style={{ marginTop: 10 }} onClick={() => {
                        setIsModal(true)

                    }}>
                        Approve
                    </CustomButton> : null}

                </Container>
                <ContainerRow>
                    {quotedata?.items?.map((item) =>
                        <div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
                            <p style={{ fontSize: '14px' }}>{item?.name} × {item?.quantity || '0'}</p>
                            <p style={{ fontSize: '14px' }}>${putCommaInvalue(taxprice(item?.price,item?.quantity))}</p>
                        </div>

                    )
                    }
                </ContainerRow>
                <TotalContainer >
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottomWidth: '1px solid #F3F3F3' }}>
                        <p style={{ fontSize: '14px' }}>Subtotal</p>
                        <p style={{ fontSize: '14px' }}>${putCommaInvalue(quotedata?.subTotalAmount)}</p>
                    </div>
                    <TaxDiv>
                        <p style={{ fontSize: '14px' }}>Tax</p>
                        <p style={{ fontSize: '14px' }}>{`$${putCommaInvalue(parseFloat(quotedata?.totalAmount - quotedata?.subTotalAmount).toFixed(2))}`}</p>
                    </TaxDiv>
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderTopWidth: '1px solid #F3F3F3' }}>
                        <p>Total</p>
                        <p>${putCommaInvalue(quotedata?.totalAmount)}</p>
                    </div>
                </TotalContainer>
                <Modal
                    open={isModal}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"

                >
                    <MainContainer1>
                        <Box sx={{ ...style, width: 350 }}>
                            <ModalHeader>
                                <h3 >Approve quote</h3>
                                <CloseIcondiv onClick={() => setIsModal(false)}>
                                    <CloseIcon />
                                </CloseIcondiv>
                            </ModalHeader>

                            <Toggle >
                                <div
                                    className={type ? 'activeclass' : 'class'}
                                    onClick={() => setType(true)}
                                >
                                    <p id='AM'>Draw</p>
                                </div>
                                <div className={!type ? 'activeclass' : 'class'}
                                    onClick={() => setType(false)}
                                >
                                    <p id='PM'>Type</p>
                                </div>
                            </Toggle>
                            {!type ? <SignedBox>
                                <Inputbox size='medium' placeholder="Type name" variant='filled' onChange={(event) => {
                                    const canvas = document.createElement('canvas');

                                    const ctx = canvas.getContext('2d');

                                    ctx.font = "bold 68px Monsieur";
                                    ctx.fillStyle = "#4169E1";

                                    ctx.fillText(event.target.value, 30, 90);
                                    const dataUrl = canvas.toDataURL();
                                   
                                    setSign(dataUrl)
                                }} />
                                <div style={{ width: '100%', height: '100px', display: 'flex', paddingTop: 40, alignItems: 'center', background: '#f3f3f3' }}>
                                    {sign ? <img style={{ fontFamily: 'Monsieur' }} src={sign} className="App-logo" alt="logo" /> : null}
                                </div>

                            </SignedBox> :
                                <div className="sigPadContainer">
                                    <SignatureCanvas
                                        ref={sigCanvas}

                                        penColor="#4169E1
                                        " canvasProps={{ className: "sigCanvas" }} />

                                    <button onClick={() => sigCanvas.current.clear()}>Clear</button>
                                </div>}
                            <p style={{ fontSize: '14px', fontWeight: 'normal' }} id="parent-modal-description">
                                By writing your signature, you accept this quote, associated costs, and any terms and conditions that may apply.
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', marginBottom: '10px' }}>
                                <SaveButton
                                    onClick={() => {
                                        if (type) {
                                            const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
                                            localStorage.setItem('sign', URL)
                                            localStorage.setItem('signType', 'draw')
                                          
                                        }
                                        else {
                                            localStorage.setItem('sign', sign)
                                            localStorage.setItem('signType', 'type')
                                        }
                                        window.open(`https://app.getnotifi.com/previewquote/${id}`);
                                        setIsModal(false)
                                    }}
                                >Save</SaveButton>
                            </div>
                        </Box>

                    </MainContainer1>
                </Modal>
            </MainContainer>
        </Main>
    );
}

export default Quote;

const TaxDiv = styled.div`
display:flex;
justify-content:space-between;
padding-bottom:15px;
border-bottom: 1px solid grey; 
`
const Main = styled.div`
display: flex;
  justify-content: center;
 
  background:#FAFAFA;
  height: 100vh;

`
const MainContainer1 = styled.div`
overflow:scroll;
.sigPadContainer {
    margin-top:15px;
    background-color: rgb(238, 235, 235);
    padding:  10px 10px;
    border:1px solid #000;
  }
  .sigCanvas{
    width: 100%;
    height: 150px;
  }
  .sigPadContainer button {
    border: none;
    margin-left: auto;
    color: rgb(78, 75, 75);
    padding: 0;
    display: block;
    margin-top: 5px;
  }
  .sigPadContainer button:hover {
    background-color: unset;
    color: black;
  }

@media (min-width: 768px) {
    body {
      zoom: 3;
    }
  }`

const ModalHeader = styled.div`
  display:flex;
  justify-content:space-between;
  border-bottom:1px solid #F3F3F3;
  padding-horizontal:15px;
  `

const MainContainer = styled.div`

@media (min-width: 820px) and (max-width:9999px)
{ 
    width: 100%;
    max-width: 420px;
}
@media (min-width: 260px) and (max-width: 820px){

    width: 100%;
    padding-left:25px;
    padding-right:25px;
    }

`
const Inputbox = styled(TextField)`
background-color:#fff;
border:1px solid #000;
border-radius:0px;
width:100%;

`
const Container = styled.div`
background:#fff;
padding:15px;
display: flex;
  justify-content: center;
  flex-direction:column;
  border:1px solid #F3F3F3;
  border-radius:8px;
`
const ContainerRow = styled.div`
background:#fff;
padding:15px;
margin-top:25px;
  border:1px solid #F3F3F3;
  border-radius:8px;
`
const TotalContainer = styled.div`
background:#fff;
padding:15px;
margin-top:25px;
display: flex;
flex-direction:column;
  border:1px solid #F3F3F3;
  border-radius:8px;
`
const CustomButton = styled.button`
width:140px;
height:44px;
border-radius:8px;
border:1px solid white;
align-self:center;
color:white;
font-size:16px;
background:#000;
cursor:pointer;
`
const CloseIcondiv = styled.div`
display:flex;
justify-content:flex-end;
padding-top:15px;
cursor:pointer;
`
const Toggle = styled.div`
width:154px;
display:flex;

margin-top:20px;

background-color:#F3F3F3;
border-radius:10px;
cursor:pointer;

.activeclass{
    display:flex;
    justify-content:center;
  color:#ffffff;
  background-color:#000;
  font-size:15px;
  border-radius:10px;
  align-items:center;
  height:40px;
  width:77px;
  text-align:center;

}
.class{
    display:flex;
    justify-content:center;
    align-items:center;
  color:#000;
  background-color:#F3F3F3;
  font-size:15px;
  border-radius:10px;
  height:40px;
  width:77px;
  text-align:center;

}
`
const SignedBox = styled.div`
border:1px solid #000;

margin-top:15px;
`
const SaveButton = styled.button`
width:100px;
height:44px;
border-radius:8px;
border:1px solid white;
align-self:center;
color:white;
font-size:16px;
background:#000;
cursor:pointer;
`