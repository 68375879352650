export const formatMobileNumber=(text)=> {
    var cleaned = ("" + text)?.replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
    var  number =  [match[1]?`+${match[1]}`:match[1], " (", match[2], ") ", match[3], "-", match[4]].join(
      ""
    );
      return number;
    }
    return text;
  }
  export const putCommaInvalue = (price) => {

    const cleanText1 = price?.toString()?.replace(/,/g, '');
    const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formatted;
  }
  export const totalprice = (price, quantity, tax) => {

    const cleanText = price?.replace(/,/g, '');
    const taxes = ((parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1) * parseFloat(tax ? tax : 0)) / 100)
    const fromatedvalue = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1) + taxes;
   
  
    return fromatedvalue
  }
  export const taxprice = (price, quantity, tax) => {
    const cleanText = price?.replace(/,/g, '');
    const taxes = ((parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1) * parseFloat(tax ? tax : 0)) / 100)
  
    return taxes;
  }

  export const TimeListAm=[
   
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '1:00 PM',
]
export const TimeListPM=[
 
  '2:00 PM',
  '3:00 PM',
  '4:00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM',
  '9:00 PM',
]

export const colorArray =[
 
 
  "#D2691E", "#DEB887", "#F4A460", "#FF8C00", "#FF4500",
  "#E9967A", "#FFDAB9", "#FFA07A", "#FF6347", "#FF4500",
  "#FFD700", "#FFA500", "#E68A00", "#FF6347", "#FF4500",
  "#FF8C00", "#FFDAB9", "#FFA07A", "#FF7F50", "#FF6347",
  "#FF4500", "#DC143C", "#CD5C5C", "#8B4513", "#A52A2A",
  "#D2691E", "#DEB887", "#F4A460", "#FF8C00", "#FF4500",
  "#E9967A", "#FFDAB9", "#FFA07A", "#FF6347", "#FF4500",
  "#FF6347", "#FF4500",
  "#FF8C00", "#FFDAB9","#FFA07A", "#FF7F50", "#FF6347",
  "#FF4500", "#DC143C", "#CD5C5C", "#8B4513", "#A52A2A",
];