import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceData, getQuotedata } from '../store/Actions/Auth.action';
import styled from 'styled-components';
import { sendNotification } from '../store/Actions/dashboard.action';
// import { Button } from '@mui/material';
import moment from 'moment'
function Quote() {
    let location = useLocation()

    let { id } = useParams()
    //const params = new URLSearchParams(windowUrl);
    const searchParams = new URLSearchParams(location.search);
    const referenceNo = searchParams.get('referenceNo');
    const email = searchParams.get('email');
    //let quote = location.pathname

    let dispatch = useDispatch()
    let navigate = useNavigate();
    useEffect(() => {
        init()
    }, [])
    const init = async () => {
        await dispatch(getInvoiceData({ referenceNo: id }))
        await dispatch(sendNotification({
            referenceNo: id,
            date: moment(new Date()).format('DD MMM, hh:mm A'),
            invoice: true
        }))


    }
    const quotedata = useSelector(state => state?.auth?.invoice)
    const quoteuserdata = useSelector(state => state?.auth?.userNameData)
    const putCommaInvalue = (price) => {

        const cleanText1 = price?.toString().replace(/,/g, '');
        const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formatted;
    }
    return (
        <Main>
            <MainContainer>
                <p style={{ fontSize: '12px', paddingTop: '20px' }}>Invoice from</p>
                <h3>{quoteuserdata?.businessName}</h3>
                <Container>
                    <h1>${putCommaInvalue(quotedata?.totalAmount)}</h1>
                    <CustomButton style={{ border: '1px solid #000', background: '#fff', color: '#000' }} onClick={() => {
                        window.open(`https://app.getnotifi.com/previewinvoice/${id}`);

                    }}>
                        View invoice
                    </CustomButton>
                    {quotedata?.paymentLink ? <CustomButton style={{ marginTop: '10px', border: '1px solid #000', }} onClick={() => {
                        window.open(quotedata?.paymentLink);

                    }}>
                        Pay invoice
                    </CustomButton> : null}
                    <p style={{ fontSize: '12px', color: 'grey' }}>Invoice #{quotedata?.numberOfInvoice}</p>
                </Container>

                <ContainerRow>
                    {quotedata?.items?.map((item) =>
                        <div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
                            <p style={{ fontSize: '14px' }}>{item?.name} × {putCommaInvalue(item?.quantity) || '0'}</p>
                            <p style={{ fontSize: '14px' }}>${putCommaInvalue(item?.price * item?.quantity)}</p>
                        </div>

                    )
                    }
                </ContainerRow>
                <TotalContainer >
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottomWidth: '1px solid #F3F3F3' }}>
                        <p style={{ fontSize: '14px' }}>Subtotal</p>
                        <p style={{ fontSize: '14px' }}>${putCommaInvalue(quotedata?.subTotalAmount)}</p>
                    </div>
                    <TaxDiv>
                        <p style={{ fontSize: '14px' }}>Tax</p>
                        <p style={{ fontSize: '14px' }}>{`$${putCommaInvalue(parseFloat(quotedata?.totalAmount - quotedata?.subTotalAmount).toFixed(2))}`}</p>
                    </TaxDiv>
                    <div style={{ display: 'flex', justifyContent: 'space-between', borderTopWidth: '1px solid #F3F3F3' }}>
                        <p>Total</p>
                        <p>${putCommaInvalue(quotedata?.totalAmount)}</p>
                    </div>
                </TotalContainer>
            </MainContainer>
        </Main>
    );
}

export default Quote;

const TaxDiv = styled.div`
display:flex;
justify-content:space-between;
padding-bottom:15px;
border-bottom: 1px solid grey; 
`
const Main = styled.div`
display: flex;
  justify-content: center;
 
  background:#FAFAFA;
  height: 100vh;

`
const MainContainer = styled.div`

@media (min-width: 820px) and (max-width:9999px)
{ 
    width: 100%;
    max-width: 420px;
}
@media (min-width: 260px) and (max-width: 820px){

    width: 100%;
    padding-left:25px;
    padding-right:25px;
    }

`
const Container = styled.div`
background:#fff;
padding:15px;
display: flex;
  justify-content: center;
  flex-direction:column;
  border:1px solid #F3F3F3;
  border-radius:8px;
`
const ContainerRow = styled.div`
background:#fff;
padding:15px;
margin-top:25px;
  border:1px solid #F3F3F3;
  border-radius:8px;
`
const TotalContainer = styled.div`
background:#fff;
padding:15px;
margin-top:25px;
display: flex;
flex-direction:column;
  border:1px solid #F3F3F3;
  border-radius:8px;
`
const CustomButton = styled.button`
width:180px;
height:44px;
border-radius:8px;
border:1px solid white;
align-self:center;
color:white;
font-size:16px;
background:#000;
cursor:pointer;
`