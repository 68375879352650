import logo from '../assets/notifilogo.png'
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQuotedata } from '../store/Actions/Auth.action';
const ReportTemplate = (props) => {
    let location = useLocation()

    let {id}=useParams()
    //const windowUrl = window.location.search;
    //const params = new URLSearchParams(windowUrl);
    const searchParams = new URLSearchParams(location.search);
    const referenceNo = searchParams.get('referenceNo');
    const email= searchParams.get('email');
    const [typesign,setTypeSign]=useState('')
    //let quote = location.pathname
    //let refnumber = quote?.[3]
// console.log(windowUrl,"num")
    let dispatch = useDispatch()

    const init=async()=>{
       await dispatch(getQuotedata({referenceNo:id}))
       props?.setloader()
    }
    useEffect(() => {
        signType()
        init()
       
    }, [])


    const quotedata = useSelector(state => state?.auth?.quote)
    console.log(quotedata, 'quotedata')
    const styles = {
        page: {
            marginLeft: '5rem',
            marginRight: '5rem',
            'page-break-after': 'always',

        },

        columnLayout: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '3rem 0 5rem 0',
            gap: '2rem',
        },

        column: {
            display: 'flex',
            flexDirection: 'column',
        },

        spacer2: {
            height: '2rem',
        },

        fullWidth: {
            //width: '100%',
            marginTop: '20px',
            height: '40px'
        },

        marginb0: {
            marginBottom: 0,
        },
        text: {
            fontSize: 14,
            fontWeight: '500'
        }
        , text1: {
            marginTop: '-10px',
            textAlign: 'start',
            fontSize: 14,
            fontWeight: '500'
        }
    };

    const getToken=() =>{
        const token =  localStorage.getItem('sign')

        return token ? ` ${token}` : ""
      }
      const signType=() =>{
        const token =  localStorage.getItem('signType')
      
        setTypeSign(token)
       
      }
      const numberClean=(cleanText)=>{
        const formatted = cleanText.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return parseFloat(formatted);
    }
    const putCommaInvalue=(price)=>{

        const cleanText1 = price?.toString().replace(/,/g, '');
        const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formatted;
      }
      const taxprice=(price,quantity)=>{
        const cleanText = price.replace(/,/g, '');
        const taxes=parseFloat(cleanText?cleanText: 0) * parseFloat(quantity?quantity:1)
        
        return taxes;
      }
    return (
        <MainContainer>
            <div style={styles.page}>
                <div style={styles.columnLayout}>
                    <div >
                        <h1 style={{ marginBottom: '-10px', textAlign: 'start' }}>
                            {"Quote"}
                        </h1>
                    </div>
                    <img style={styles.fullWidth} src={logo} alt='logo' />
                </div>

                <Container >
                    {/* <div className='detailCont'>
                        <h4 style={{ fontSize: 14 }}>Swiftbel inc.</h4>
                        <p style={styles.text}>{`651  N Broad StSuite 206Middletown`}</p>
                        <p style={styles.text1}> Delaware 19709United States</p>
                        <p style={styles.text1}>16043584116</p>
                        <p style={styles.text1}>robertprasher@icloud.com</p>
                    </div> */}
                    <div className='detailCont'>
                        <h4 style={{ fontSize: 14 }}>Bill to</h4>
                        {quotedata?.name ? <p style={styles.text}>{quotedata?.name ? quotedata?.name : '-'}</p> : null}
                        {quotedata?.phone ? <p style={styles.text1}>{quotedata?.phone ? quotedata?.phone : '-'}</p> : null}
                        {quotedata?.email ? <p style={styles.text1}>{quotedata?.email ? quotedata?.email : '-'}</p> : null}
                        {quotedata?.customerAddress ? <p style={styles.text1}>{quotedata?.customerAddress ? quotedata?.customerAddress : '-'}</p> : null}
                        {/* <p style={styles.text1}> Delaware 19709United States</p> */}

                    </div>
                </Container>
                {/* <img style={styles.fullWidth} src="photo-2.png" /> */}
            </div>

            <div style={styles.page}>
                <div>
                    <h3 style={{textAlign:'center'}}>Thank you for your business! </h3>
                </div>

                <div >
                    <Table>

                        <tr style={{ backgroundColor: '#fff', borderBottom: '2pt solid #000' }}>

                            <td>{"Description"}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{"Quantity"}</td>



                            <td>{"Unit price"}</td>

                            <td></td>


                            <td>{"Amount"}</td>

                        </tr>

                        {quotedata?.items?.map((x, index) => {
                            return (<>
                                <tr style={{ backgroundColor: '#fff' }}>
                                    <td>{x?.description ? x?.description : '-'}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{x?.quantity ? x?.quantity : '-'}</td>


                                    <td>${x?.price ? x.price : '0'}</td>

                                    <td></td>


                                    <td>${x?.price && x?.quantity ? putCommaInvalue(taxprice(x?.price,x?.quantity)): putCommaInvalue(parseFloat(numberClean(x?.price)))}</td>

                                </tr>

                            </>
                            )
                        })}
                        <tr style={{ backgroundColor: '#fff' }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{"Sub total"}</td>
                            <td></td>
                            <td></td>
                            <td>{`$${putCommaInvalue(quotedata?.subTotalAmount)}`}</td> 

                        </tr>
                        <tr style={{ backgroundColor: '#fff' }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{"Tax"}</td>
                            <td></td>
                            <td></td>
                            <td>{`$${putCommaInvalue(parseFloat(quotedata?.totalAmount-quotedata?.subTotalAmount)?.toFixed(2))}`}</td> 

                        </tr>
                        <tr style={{ backgroundColor: '#fff' }}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{"Total"}</td>
                            <td></td>
                            <td></td>
                            <td >{`$${putCommaInvalue(quotedata?.totalAmount)}`}</td> 

                        </tr>
                    </Table>

                    <Container >
                    <div >
                    <div style={{ width: '250px',borderBottom:'2px solid #000', display: 'flex', paddingTop: '60px', alignItems: 'center',}}>
                        <p style={{fontSize:'12px',alignSelf:'flex-end'}}>{'12 feb 2022'}</p>
                                    <img style={{ fontFamily: 'Monsieur' }} src={getToken()}  className={typesign==="draw"?"signature":"signature1"} alt="logo" />
                                </div>
                        <div style={{display:'flex'}}>
                        <p style={{fontSize:'12px',marginRight:'70px'}}>date</p>
                        <p style={{fontSize:'12px'}}>Client signature</p>
                        </div>
                        </div>
                        </Container>
                    
                </div>



            </div>
        </MainContainer>
    );
};

export default ReportTemplate;
const MainContainer = styled.div`
overflow:auto;
`

const Container = styled.div`
display:flex;
 gap:2rem;
 .detailCont{
     width:50%;
     text-align:start;
 }
 .signature {
    width: 120px;
    height: 52px;
    margin-top: 20px;
  
    padding: 15px;
    object-fit: contain;
  }
  .signature1 {
    width: 150px;
    height: 70px;
    margin-top: 20px;
    position:absolute;
    margin-bottom:20px;
    left:150px;
    padding: 15px;
    object-fit: contain;
  }
`
const Table = styled.table`
border-collapse: collapse;
  width: 100%;
text-align:start;

.divider{
font-weight:300;
line-height: 235%;
}

  tr:nth-child(odd){background-color: #FAFAFA}
  th:first-child{
    border-radius:10px 0 0 0px;
  }

  th:last-child{
    border-radius:0 10px 0px 0;
  }

  th {
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: white;
    color: black;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  td{
    font-family: Inter;
font-style: normal;
font-weight: 400;
font-size: 14px;
padding:10px
  }
`