import { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import ReportTemplate from './ReportTemplate';
import styled from 'styled-components';
import customFont from '../../src/assets/fonts/MonsieurLaDoulaise-Regular.ttf'
import { useDispatch } from 'react-redux';
import { saveSign } from '../store/Actions/Auth.action';
import { useLocation, useParams } from 'react-router-dom';
// import { Button } from '@mui/material';

function Quote() {
	const dispatch = useDispatch()
	let location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const referenceNo = searchParams.get('referenceNo');
	const email= searchParams.get('email');
	let {id}=useParams()
	const [loader,setloader]=useState(true)
	const getToken=() =>{
        const token =  localStorage.getItem('sign')
        console.log(token,"ddjhbj")
        return token ? ` ${token}` : ""
      }
	const init = () => {
if(!loader&&getToken()){
		let blob
		const doc = new jsPDF({
			orientation: 'P',
			format: 'a3',
			unit: 'pt',
		});
		doc.addFileToVFS(customFont);
		doc.addFont('Monsieur', 'custom', 'normal');
		doc.setFont('Monsieur');
		doc.text('Total', 10, 20);
		doc.html(reportTemplateRef.current, {
			async callback(doc) {
				blob =  new File([doc.output("blob")], "myDoc.pdf", {
					type: "application/pdf",
				});

				const res = await dispatch(saveSign(blob,id,email))
				console.log( new File([doc.output("blob")], "myDoc.pdf", {
					type: "application/pdf",
				}))
				// await doc.save('document');
				// window.open(doc.output('bloburl'))
				// const pdfDataUri = doc.output('datauristring');
				// window.open(pdfDataUri);
			},
			width: 800,
			windowWidth: 800
		});
	}
	}
	useEffect(()=>{
		init()
	},[loader])

	const reportTemplateRef = useRef(null);
	const handleGeneratePdf = () => {
		let blob
		const doc = new jsPDF({
			orientation: 'P',
			format: 'a3',
			unit: 'pt',
		});
		doc.addFileToVFS(customFont);
		doc.addFont('Monsieur', 'custom', 'normal');
		doc.setFont('Monsieur');
		doc.text('Total', 10, 20);
		doc.html(reportTemplateRef.current, {
			async callback(doc) {
				blob =  new File([doc.output("blob")], "myDoc.pdf", {
					type: "application/pdf",
				});

				const res = await dispatch(saveSign(blob,id,email))
				console.log( new File([doc.output("blob")], "myDoc.pdf", {
					type: "application/pdf",
				}))
				await doc.save('document');
				window.open(doc.output('bloburl'))
				// const pdfDataUri = doc.output('datauristring');
				// window.open(pdfDataUri);
			},
			width: 800,
			windowWidth: 800
		});
		// const pdfs = new File([doc.output("blob")], "myDoc.pdf", {
		// 	type: "application/pdf",
		// });
		// console.log(pdfs, "djfhhjf")

	};
	useEffect(() => {
		const meta = document.createElement('meta');
		meta.name = 'viewport';
		meta.content = 'width=device-width, initial-scale=0.5';

		const head = document.head;
		head.appendChild(meta);

		return () => {
			head.removeChild(meta);
		};
	}, []);
	return (
		<MainContainer>
			<div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '3rem' }}>
				<CustomButton onClick={handleGeneratePdf}>
					⬇	Download
				</CustomButton>
			</div>
			<div ref={reportTemplateRef}>
				<ReportTemplate
				setloader={()=>setloader(false)}
				/>
			</div>
		</MainContainer>
	);
}

export default Quote;
const MainContainer = styled.div`
overflow:scroll;
@media (min-width: 768px) {
    body {
      zoom: 3;
    }
  }

`
const CustomButton = styled.button`
width:130px;
height:44px;
margin-top:15px;
border-radius:8px;
border:1px solid #4169E1;
color:white;
font-size:16px;
background:#4169E1;
cursor:pointer;
`