
import NetworkOps from "../../services/NetworkOps";
import { ServiceEnum } from "../../services/Urls";


export const registerUser = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.register, data)
  if (res.status === true) {
    localStorage.setItem('token', res?.token)
    dispatch({
      type: 'USERID',
      payload: res?.data
    })
  }
  return res
}
export const displaynumber = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.displayNumber, data)
 
  if (res.status === true) {
    dispatch({
      type: 'DISPNUMBER',
      payload: res?.data
    })
  }
  return res
}
export const buynumber = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.buyNumber, data)
  localStorage.setItem('number',  JSON.stringify(data))
  if (res.status === true) {
    dispatch({
      type: 'BUYNUMBER',
      payload: res?.data
    })
  }
  return res
}

export const loginUsers = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(ServiceEnum.loginUser, data)
  if (res.status === true) {
    localStorage.setItem('token', res?.token)
    localStorage.setItem('number', res?.data?.twilioPhone)
    dispatch({
      type: 'LOGIN_TOKEN',
      payload: res?.token
    })
  }
  return res
}

export const getpaymentIntent = (value) => async (dispatch) => {
  const res = await NetworkOps.post(`${ServiceEnum.paymentLink}`, value)
  if (res.status === true) {
    dispatch({
      type: 'PAYMENT',
      payload: res?.token
    })
  }
  return res
}

export const getLocationDetails = (value) => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.locationDetails}?referenceNo=${value}`)
  if (res.status === true) {
    dispatch({
      type: 'LOCATION',
      payload: res.data
    })
  }
  return res;
}

export const getTrackingDetails = () => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.livetracker}`)
  if (res.status === true) {
    dispatch({
      type: 'TRACKING',
      payload: res.data
    })
  }
  return res;
}
export const getUserData = () => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.userdata}`)
  if (res.status === true) {
    dispatch({
      type: 'USERDATA',
      payload: res.data
    })
  }
  return res;
}
export const getQuotedata = (value) => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.quotedata}?referenceNo=${value.referenceNo}`)
  if (res.status === true) {
    dispatch({
      type: 'QUOTE',
      payload: res.data?.data
    })
    dispatch({
      type: 'USERNAME',
      payload: res.data?.userData
    })
  }
  return res
}
export const getInvoiceData = (value) => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.invoiceData}?referenceNo=${value.referenceNo}`)
  if (res.status === true) {
    dispatch({
      type: 'INVOICE',
      payload: res.data?.data
    })
    dispatch({
      type: 'USERNAME',
      payload: res.data?.userData
    })
  }
  return res
}

export const getAllconversations = () => async (dispatch) => {
  const res = await NetworkOps.get(`${ServiceEnum.fetchAllConvo1}`)
  const Dates = res?.data?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
  if (res.status === true) {
    dispatch({
      type: 'CONVO',
      payload: Dates
    })
  }
  return res
}


export const chatgprtApi = (data) => async (dispatch, getstate) => {
  
  const res = await NetworkOps.post(`${ServiceEnum.ChatgptApi}`,data,{
    'Authorization': `Bearer ${process.env.REACT_APP_ENVIRONMENT}`, 
    'Content-Type': 'application/json'
    
  })
  return res
}

export const getAlloutchats = (number, value) => async (dispatch) => {
  const res = await NetworkOps.post(`${ServiceEnum.chats}?to=${number}&from=${value}`)
  if (res.status === true) {
    dispatch({
      type: 'OUTCHATS',
      payload: res.data
    })
  }
  return res
}

export const getAllinchats = (value) => async (dispatch) => {
  const res = await NetworkOps.post(`${ServiceEnum.chats}`, {
    "to": value
  })
  if (res.status === true) {
    dispatch({
      type: 'INCHATS',
      payload: res.data
    })
  }
  return res
}
export const chatgprtImgApi1 = (data) => async (dispatch, getstate) => {
  const res = await NetworkOps.post(`${ServiceEnum.generateImg}`,data,{
    'Authorization': 'Bearer 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', 
    'Content-Type': 'application/json'
  })
  return res
}

export const getnumber = (number) => async (dispatch) => {
  dispatch({
    type: 'GLOBENUM',
    payload: number
  })
  return number
}

export const getcolor = (color) => async (dispatch) => {
  dispatch({
    type: 'GLOBECOLOR',
    payload: color
  })
  return color
}
export const saveSign=(data,value,email)=>async(dispatch)=>{
  var details = new FormData();

  // details.append("", data);
  details.append("bookingId", value);
  details.append("email", email);
  const res=await NetworkOps.post(ServiceEnum.saveSign,details)
  return res
}

export const getname = (name) => async (dispatch) => {
  dispatch({
    type: 'GLOBENAME',
    payload: name
  })
  return name
}
export const activemenu = (name) => async (dispatch) => {
  dispatch({
    type: 'ACTIVEMENU',
    payload: name
  })
  return name
}
export const sendMessage = (data) => async (dispatch) => {
  const res = await NetworkOps.post(ServiceEnum.sendMessage, data)
  if (res.status === true) {
    dispatch({
      type: 'SENDMESSAGE',
      payload: res?.data
    })
  }
  dispatch(getAllconversations(data?.from))
  return res
}
export const sendPhoneCode = (data) => async (dispatch, getstate) => {
  
  const res = await NetworkOps.post(`${ServiceEnum.sendPhoneCode}`, {
    "number": `${data}`
  })

  return res
}
export const phoneVerification = (data) => async (dispatch, getstate) => {
 
  const res = await NetworkOps.post(`${ServiceEnum.phoneVerification}`, {
    "number": `${data?.number}`,
    "code": data?.code
  })
  return res
}