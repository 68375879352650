
export const LOCAL_HOST='https://apis.getnotifi.com';
export const fromnumber='+15878064483'
export const renderTop =()=>{
    window.scroll({
    top:0,
    left:0,
    behavior:'smooth',
    });
    };

export const ServiceEnum={
    register:"user/notify/signup",
    loginUser:'user/notify/login',
    userDetails: "user/notify/getUserData",
    displayNumber:'user/display',
    buyNumber:'user/orderNumber',
    paymentLink:'user/notify/subscription',
    locationDetails:'user/notify/getLocation',
    livetracker:'tracker',
    conversations:'twilio/fetchAllConversation',
    fetchAllConvo1:'user/v2/getAllConversations',
    chats:'user/v3/getConversation',
    sendMessage:'user/createConversation',
    quotedata:'booking/notify/getQuote',
    userdata:'user/notify/getUserData',
    sendPhoneCode:'user/getVerficationCode',
    invoiceData:'booking/notify/getInvoice',
    phoneVerification:'user/confirmVerficationCode',
    saveSign:'user/notify/saveSignature',
    ChatgptApi:'https://api.openai.com/v1/chat/completions',
    job:'https://api.openai.com/v1/fine_tuning/jobs',
    generateImg:'https://api.openai.com/v1/images/generations',
    notificationSend:'user/sendNotification',
    getContactDetail:'user/notify/getActivity',
    fetchAllContactsV2:'user/v2/notify/fetchContact',
    editContact:'user/notify/editContact',
    createBooking:'booking/notify/createBooking',
    sendInvoice:'booking/notify/sendInvoice',
    sendQoute:'booking/notify/sendQuote',
    updateDetail:'user/notify/updateDetails',
    getInfoDetail:'user/notify/getUser',
    getBookingsApi:'booking/notify/booking/getDetial',
    QuoteInvoice:'booking/notify/getQuotesAndInvoice',
    resetpassword:'user/notify/resetPassword',
    saveTax:'user/notify/saveTax',
    AddNumber:'user/notify/saveContact',
    inviteWorker: 'user/notify/sendInviteLinkToWokers',
    editInvoice:'booking/notify/editInvoice',
    editQuote:'booking/notify/editQuote',
    dlcbusinessVerfication:'user/createBrand',
    teamFetch:'booking/notify/getTeamBooking',
    assignedJob: 'user/notify/v2/assignJob',
    status:'booking/notify/changeStatus',
}

export const urlFor =(services)=>{
    if(services==='https://api.openai.com/v1/chat/completions'||services==='https://api.openai.com/v1/fine_tuning/jobs'||services==='https://api.openai.com/v1/images/generations'){
        return `${services}`
    }
    else{
        return `${LOCAL_HOST}/${services}`
    }
}